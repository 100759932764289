import React from "react"
import { Link, PageProps } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage: React.FC<PageProps> = ({ location }) => (
  <Layout location={location}>
    <SEO
      title="Personal Finance"
      description="The personal finance platform for high-net-worth individuals, families and their trusted advisors."
    />
  </Layout>
)

export default IndexPage
